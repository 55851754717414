import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faPinterestSquare,
  faYelp,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faBars, faAngleDown, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import TradeInLayout from './trade-in-layout/trade-in-layout';
import CashOfferLayout from './cash-offer-layout/cash-offer-layout';
import { SITE_TYPE, getSiteType } from '../utils/site-type-validator';

library.add(
  fab,
  faBars,
  faInstagramSquare,
  faLinkedin,
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faPinterestSquare,
  faYelp,
  faAngleDown,
  faEnvelope,
  faPhone,
);

const Layout = (props) => {
  const { children, pageContext, location } = props;
  const type = getSiteType(location.pathname);

  console.log(location, type);

  if (type === SITE_TYPE.CASH_OFFER) {
    return <CashOfferLayout {...props}>{children}</CashOfferLayout>;
  }

  return <TradeInLayout {...props}>{children}</TradeInLayout>;
};

Layout.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

Layout.defaultProps = { children: null, pageContext: {}, location: {} };

export default Layout;
