import cs from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './hero-container.scss';
import heroImage from '../../../images/hero.jpeg';

const HeroContainer = (props) => {
  const { openModalHandler } = props;

  return (
    <div className={cs('on-hero')}>
      <div className="section" id="hero">
        <picture>
          <img alt="Modern home" src={heroImage} />
        </picture>
        <div className="content">
          <div className="intro-box">
            <h1>Become an All-Cash Buyer. Win Your Dream Home.</h1>
            <p>
              Powered by HomeLight Cash Offer<sup>TM</sup>
            </p>
            <p>
              HomeLight makes an all-cash offer on your behalf. That has real value. Cash offers are 3x more likely to
              win and can get you a 5% savings, on average, versus offers with a loan.*
            </p>
            <a className="button" onClick={() => openModalHandler(true)}>
              Start here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

HeroContainer.defaultProps = { openModalHandler: () => {} };

export default memo(HeroContainer);
