import cs from 'classnames';
import React, { memo, useState, useEffect } from 'react';

import { TestimonialSlider, VideoPlayer, PrevArrow, NextArrow } from '@components';

import SlideTemplate from './slide-template';

import './testimonials-container.scss';

const windowGlobal = typeof window !== 'undefined' && window;

const testimonials = [
  {
    id: 0,
    quote:
      'HomeLight Trade-In enabled us to break out of our current budget into a higher budget. Moving into a bigger house with a swimming pool and a huge backyard are definitely things you appreciate every day. I’m pretty confident we would not have ended up here if it weren’t for HomeLight.',
    link: {
      url: 'https://www.homelight.com/blog/buyer-trade-in-antwerp-lane-house/',
      text: 'Read the full story',
    },
    author: {
      displayName: 'Omer Tripp',
      description: 'Homelight Trade-in Client',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-43b78afe16d0e37777ca2f83a4e1d8c422f6ea9f21dd14a608dc71165852ccb8.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/omer-tripp-92a1f21db23072652be08fabb81485763ce14c4b2926a9be2a541afcdd3a4370.jpg',
    },
    imgAlt: 'Picture of Omer Tripp',
  },
  {
    id: 1,
    quote:
      'Using HomeLight is kind of like shopping on Amazon, you don’t have to go to a bunch of different stores to try to find what you’re looking for, you just make a few clicks and find what you need and you’re done... it’s definitely the biggest change in real estate that I’ve seen in my career.',
    link: {
      url: 'https://www.homelight.com/blog/trade-in-levante-street-house/',
      text: 'Read the full story',
    },
    author: {
      displayName: 'Lauren Boston',
      description: 'HomeLight Trade-In Certified Agent',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-011e36bc057f5dcc80eba907700726e953e2adc8dc2c5ec76bc640cae8cc8e7c.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/lauren-boston-93bc43d6d3dae32597f1ffcd8a56a5c9f589a85722aed35f9bffc2172d7496ed.jpg',
    },
    imgAlt: 'Picture of Lauren Boston',
  },
  {
    id: 2,
    quote:
      'If there was an easy button in real estate transactions, this would be it. We were able to buy our perfect home because we didn\'t have to wait to list our house and sell it to have a bona fide offer.',
    link: {
      url: 'https://www.homelight.com/blog/trade-in-cross-country-house/',
      text: 'Read the full story',
    },
    author: {
      displayName: 'Eric and Nicole Tilford',
      description: 'HomeLight Trade-In Clients',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-92bec948118ac324051dd37ea7599553d7d64a39bd9234df63633abb48c318a4.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/eric-nicole-tilford-d09ded382fed8a7b2f0af53d575e54e89821f56dcd501db1f51ea9fd0d5e8488.jpg',
    },
    imgAlt: 'Picture of Eric and Nicole Tilford',
  },
  {
    id: 3,
    quote:
      'The HomeLight Trade-In product is probably the greatest thing that has come along in the last, I think 12 years in my opinion. I think the major benefits of using HomeLight Trade-In is to give your seller the option to be able to buy at the same time that they\'re selling.',
    link: {
      url: 'https://www.homelight.com/blog/trade-in-cross-country-house/',
      text: 'Read the full story',
    },
    author: {
      displayName: 'Kelli Griggs',
      description: 'HomeLight Trade-In Certified Agent',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-0ffb6bf3e153cdc7f4e90166c33ae1d9339ee3830646639e5c2143c2bb18a60b.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelli-griggs-a4b1ade4a5666ca8f44170e466f85942fe6ec92cfb880a0cfc4bbc4fbb2aeb52.jpg',
    },
    imgAlt: 'Picture of Kelli Griggs',
  },
  {
    id: 4,
    quote:
      'The HomeLight Trade-In process only took a few weeks! It was a real estate miracle — and exactly what I needed to facilitate my transition of moving to a new home, state, and job in less than six weeks!',
    link: null,
    author: {
      displayName: 'Laura Gottlieb',
      description: 'HomeLight Trade-In Client',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-00cfd425563852e12c1622d54ec1ec215da5beb639af7b036d7b556f9f49a2f0.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/laura-gottlieb-0d768bb4b6dd33959a99ca1d01f88f61d3b1ef35f324bf13266ced47490b5696.jpg',
    },
    imgAlt: 'Picture of Laura Gottlieb',
  },
  {
    id: 5,
    quote:
      'The idea of getting a home ready to sell while you live there is overwhelming. HomeLight Trade-In removes that stress and allows potential buyers complete flexibility to view your home at their convenience. By vacating and staging our home after we moved out, we were able to sell our home for more money.',
    link: {
      url: 'https://www.homelight.com/blog/trade-in-sitio-granado-house/',
      text: 'Read the full story',
    },
    author: {
      displayName: 'Suzanne Williamson',
      description: 'HomeLight Trade-In Client',
    },
    imgSource: {
      webp: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-2d078461d8813d7564729278317fa275d8cc433655cdba52eaf665817867423b.webp',
      jpg: 'https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/williamson-family-3060a13f4883b107ef06177418f4c67a34ff62bf1a9f4ef3ec5440cc09a1fbcd.jpg',
    },
    imgAlt: 'Picture of Suzanne Williamson',
  },
];

const getShownSlideCount = () => (windowGlobal.innerWidth >= 1200 ? 4 : Math.floor(windowGlobal.innerWidth / 250));

const TestimonialsContainer = () => {
  const [slidesToShow, setSlidesToShow] = useState(getShownSlideCount());

  const [settings] = useState({
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  });

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getShownSlideCount());
    };

    windowGlobal.addEventListener('resize', handleResize);
  });

  return (
    <div className={cs('on-testimonials')}>
      <div className="section testimonials">
        <div className="content">
          <h2>Don’t take our word for it, take theirs.</h2>
        </div>
        <div className="section-media section-video">
          <div className="content">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="inner testimonial-blockquote">
                  <blockquote>
                    <q>
                      {`The fact that we're both teachers on teacher incomes, and we were able to use the HomeLight
                      Trade-In program to live in the house of our dreams is pretty incredible.`}
                    </q>
                    <br />
                    <br />
                    <cite>
                      <strong>Jason Castro</strong>
                      <br />
                      <span>HomeLight Trade-In Client</span>
                    </cite>
                  </blockquote>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 xs-first">
                <VideoPlayer url="https://player.vimeo.com/video/469098176?autoplay=1" />
              </div>
            </div>
          </div>
        </div>

        <div className={cs('testimonial-slider-container')}>
          <TestimonialSlider settings={settings}>
            {testimonials.map((testimonial) => (
              <SlideTemplate {...testimonial} key={testimonial.id} />
            ))}
          </TestimonialSlider>
        </div>
      </div>
    </div>
  );
};

export default memo(TestimonialsContainer);
