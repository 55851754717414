export const menuItems = [
  {
    id: 1,
    htmlId: 'menu-Buyers',
    displayName: 'Buyers', // TODO: I18n this
    url: 'https://www.hoffmanmurphy.com/buyers/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 101,
        htmlId: 'menu-listings-active',
        displayName: 'Buying a Home', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/buying-a-home/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 102,
        htmlId: 'menu-listings-new',
        displayName: 'Thinking Of Purchasing A Home Or Investment?', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/thinking-of-purchasing-a-home-or-investment/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 103,
        htmlId: 'menu-listings-weekend',
        displayName: 'Find Your Dream Home', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/find-your-dream-home/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 104,
        htmlId: 'menu-listings-sold',
        displayName: 'How Much Can I Afford?', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/how-much-can-i-afford/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: false,
  },
  {
    id: 2,
    htmlId: 'menu-Sellers',
    displayName: 'Sellers', // TODO: I18n this
    url: 'https://www.hoffmanmurphy.com/sellers/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 201,
        htmlId: 'menu-listings-active',
        displayName: 'Selling a Home', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/selling-a-home/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 202,
        htmlId: 'menu-listings-new',
        displayName: 'What’s My Home Worth', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/what-s-my-home-worth-sellers/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 203,
        htmlId: 'menu-listings-weekend',
        displayName: 'How To Price Your Home', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/how-to-price-your-home-2/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 204,
        htmlId: 'menu-listings-sold',
        displayName: 'Why Hire The Hoffman Murphy Team?', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/why-hire-the-hoffman-murphy-team-sellers/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 205,
        htmlId: 'menu-listings-soon',
        displayName: 'Transformation', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/transformation/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 206,
        htmlId: 'menu-listings-soon',
        displayName: 'Late On Your Payments?', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/late-on-your-payments/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 3,
    htmlId: 'menu-Listings',
    displayName: 'Listings', // TODO: I18n this
    url: 'https://www.hoffmanmurphy.com/listings-page/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 301,
        htmlId: 'menu-listings-active',
        displayName: 'Neighborhoods', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/neighborhoods/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 302,
        htmlId: 'menu-listings-new',
        displayName: 'Our Listings', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/our-listings/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 303,
        htmlId: 'menu-listings-weekend',
        displayName: 'Sold', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/sold/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 304,
        htmlId: 'menu-listings-sold',
        displayName: 'Leasing Division', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/leases/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-About Us',
    displayName: 'About Us', // TODO: I18n this
    url: 'https://www.hoffmanmurphy.com/about-the-team/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 401,
        htmlId: 'menu-listings-active',
        displayName: 'Meet the Team', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/meet-the-team/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 402,
        htmlId: 'menu-listings-new',
        displayName: 'Our Stats', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/our-stats/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 403,
        htmlId: 'menu-listings-weekend',
        displayName: 'Vendors', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/vendors/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 404,
        htmlId: 'menu-listings-sold',
        displayName: 'Events', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/events/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 405,
        htmlId: 'menu-listings-soon',
        displayName: 'Testimonials', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/testimonials/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 406,
        htmlId: 'menu-listings-soon',
        displayName: 'Reel and Reviews', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/reel-and-reviews/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-Make a Difference',
    displayName: 'Make a Difference', // TODO: I18n this
    url: 'https://www.hoffmanmurphy.com/make-a-difference/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 406,
        htmlId: 'menu-listings-soon',
        displayName: 'Redondo Beach Education Foundation', // TODO: I18n this
        url: 'https://www.hoffmanmurphy.com/rbef/',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];
